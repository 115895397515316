const SearchIcon = ({ size, className }) => (
  <svg
    width={size === 'small' ? '20' : size === 'md' ? '22' : '24'}
    height={size === 'small' ? '20' : size === 'md' ? '22' : '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.54963 10.3593C4.54963 7.15492 7.15592 4.5497 10.3603 4.5497C13.5647 4.5497 16.171 7.15492 16.171 10.3593C16.171 13.5636 13.5647 16.1709 10.3603 16.1709C7.15592 16.1709 4.54963 13.5636 4.54963 10.3593ZM20.7731 19.678L16.0812 14.985C17.1049 13.7196 17.7205 12.1102 17.7205 10.3593C17.7205 6.30063 14.419 3.0002 10.3603 3.0002C6.30162 3.0002 3.00011 6.30063 3.00011 10.3593C3.00011 14.4179 6.30162 17.7204 10.3603 17.7204C12.1103 17.7204 13.7207 17.1037 14.9851 16.08L19.6781 20.7729C19.8289 20.9248 20.0273 21.0002 20.2256 21.0002C20.4239 21.0002 20.6223 20.9248 20.7731 20.7729C21.0758 20.4703 21.0758 19.9796 20.7731 19.678Z"
      // fill="white"
      className={className}
    />
  </svg>
)

export default SearchIcon
