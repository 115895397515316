const InfoIcon = () => (
  <svg
    className="rotate-180"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    id="info"
    fill="#c1c1c1"
    width={24}
    height={24}
  >
    <g>
      <g>
        <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"></path>
        <circle cx="12" cy="8" r="1"></circle>
        <path d="M12 10a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0v-5a1 1 0 0 0-1-1z"></path>
      </g>
    </g>
  </svg>
)

export default InfoIcon
