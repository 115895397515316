const WiFiIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="36px"
    height="36px"
    viewBox="0 0 98.000000 98.000000"
  >
    <g
      transform="translate(0.000000,98.000000) scale(0.100000,-0.100000)"
      fill="white"
      stroke="none"
    >
      <path
        d="M360 716 c-77 -22 -170 -77 -170 -99 0 -7 7 -20 15 -31 13 -18 15
-18 42 2 52 36 108 59 176 72 104 19 217 -7 310 -72 27 -20 29 -20 42 -2 8 11
14 26 12 34 -3 17 -96 75 -147 91 -54 17 -228 20 -280 5z"
      />
      <path
        d="M420 597 c-65 -15 -144 -56 -147 -77 -2 -8 3 -23 11 -33 13 -17 17
-16 66 15 48 30 58 32 140 32 82 0 92 -2 140 -32 49 -31 53 -32 66 -15 21 27
13 45 -31 70 -75 43 -165 57 -245 40z"
      />
      <path
        d="M433 471 c-41 -11 -83 -38 -83 -54 1 -20 30 -53 38 -42 33 47 171 47
204 0 9 -13 39 25 35 44 -6 38 -124 69 -194 52z"
      />
      <path
        d="M455 326 c-18 -27 -11 -53 16 -61 26 -8 56 2 63 22 9 22 -20 63 -44
63 -11 0 -26 -11 -35 -24z"
      />
    </g>
  </svg>
)

export default WiFiIcon
