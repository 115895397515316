const ScreenIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="36px"
    height="36px"
    viewBox="0 0 98.000000 98.000000"
  >
    <path
      d="M0 0 C4.83007868 4.37007118 7.43818919 9.57283787 7.875 16.125 C7.78078456 21.83026849 5.91623052 25.92236566 2.125 30.1875 C-2.93728481 34.61699921 -6.8046403 35.29499562 -13.375 35.375 C-18.37717265 34.39096604 -23.46438509 33.08331272 -26.53515625 28.73046875 C-29.74273063 23.26954462 -31.01332931 18.67705114 -30.375 12.375 C-28.28116002 5.87307584 -25.00826014 2.13050676 -19.375 -1.625 C-12.85035206 -3.3755153 -6.0671259 -2.92120877 0 0 Z "
      fill="white"
      transform="translate(60.375,32.625)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C5 0.66 5 1.32 5 2 C5.66 2 6.32 2 7 2 C7 2.66 7 3.32 7 4 C7.66 4 8.32 4 9 4 C9.33 6.31 9.66 8.62 10 11 C7.69 10.67 5.38 10.34 3 10 C3 9.34 3 8.68 3 8 C2.34 8 1.68 8 1 8 C-1 5 -1 5 -0.625 2.3125 C-0.41875 1.549375 -0.2125 0.78625 0 0 Z "
      fill="white"
      transform="translate(64,63)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C5.625 2.3125 5.625 2.3125 6 5 C5.01 6.485 5.01 6.485 4 8 C3.34 8 2.68 8 2 8 C2 8.66 2 9.32 2 10 C-0.31 10.33 -2.62 10.66 -5 11 C-4.67 8.69 -4.34 6.38 -4 4 C-3.34 4 -2.68 4 -2 4 C-2 3.34 -2 2.68 -2 2 C-1.34 2 -0.68 2 0 2 C0 1.34 0 0.68 0 0 Z "
      fill="white"
      transform="translate(29,63)"
    />
    <path
      d="M0 0 C-0.33 2.31 -0.66 4.62 -1 7 C-1.66 7 -2.32 7 -3 7 C-3 7.66 -3 8.32 -3 9 C-3.66 9 -4.32 9 -5 9 C-5 9.66 -5 10.32 -5 11 C-6.65 11 -8.3 11 -10 11 C-10.625 8.6875 -10.625 8.6875 -11 6 C-10.34 5.01 -9.68 4.02 -9 3 C-8.34 3 -7.68 3 -7 3 C-7 2.34 -7 1.68 -7 1 C-4.53721199 -0.231394 -2.7204945 -0.07159196 0 0 Z "
      fill="white"
      transform="translate(74,24)"
    />
    <path
      d="M0 0 C2.31 0.33 4.62 0.66 7 1 C7 1.66 7 2.32 7 3 C7.66 3 8.32 3 9 3 C11 6 11 6 10.625 8.6875 C10.41875 9.450625 10.2125 10.21375 10 11 C8.35 11 6.7 11 5 11 C5 10.34 5 9.68 5 9 C4.34 9 3.68 9 3 9 C3 8.34 3 7.68 3 7 C2.34 7 1.68 7 1 7 C0.67 4.69 0.34 2.38 0 0 Z "
      fill="white"
      transform="translate(24,24)"
    />
    <path
      d="M0 0 C1.65322266 0.01740234 1.65322266 0.01740234 3.33984375 0.03515625 C4.99693359 0.04869141 4.99693359 0.04869141 6.6875 0.0625 C7.54214844 0.07410156 8.39679688 0.08570313 9.27734375 0.09765625 C9.65953142 1.75380282 9.99129477 3.42222649 10.27734375 5.09765625 C9.27734375 6.09765625 9.27734375 6.09765625 6.5546875 6.1953125 C4.90146484 6.17791016 4.90146484 6.17791016 3.21484375 6.16015625 C1.55775391 6.14662109 1.55775391 6.14662109 -0.1328125 6.1328125 C-0.98746094 6.12121094 -1.84210938 6.10960937 -2.72265625 6.09765625 C-3.10484392 4.44150968 -3.43660727 2.77308601 -3.72265625 1.09765625 C-2.72265625 0.09765625 -2.72265625 0.09765625 0 0 Z "
      fill="white"
      transform="translate(73.72265625,45.90234375)"
    />
    <path
      d="M0 0 C1.65322266 0.01740234 1.65322266 0.01740234 3.33984375 0.03515625 C4.99693359 0.04869141 4.99693359 0.04869141 6.6875 0.0625 C7.54214844 0.07410156 8.39679687 0.08570313 9.27734375 0.09765625 C9.65953142 1.75380282 9.99129477 3.42222649 10.27734375 5.09765625 C9.27734375 6.09765625 9.27734375 6.09765625 6.5546875 6.1953125 C4.90146484 6.17791016 4.90146484 6.17791016 3.21484375 6.16015625 C1.55775391 6.14662109 1.55775391 6.14662109 -0.1328125 6.1328125 C-0.98746094 6.12121094 -1.84210937 6.10960937 -2.72265625 6.09765625 C-3.10484392 4.44150968 -3.43660727 2.77308601 -3.72265625 1.09765625 C-2.72265625 0.09765625 -2.72265625 0.09765625 0 0 Z "
      fill="white"
      transform="translate(17.72265625,45.90234375)"
    />
    <path
      d="M0 0 C1.98 0 3.96 0 6 0 C6 3.96 6 7.92 6 12 C4.02 12 2.04 12 0 12 C0 8.04 0 4.08 0 0 Z "
      fill="white"
      transform="translate(46,71)"
    />
    <path
      d="M0 0 C1.98 0 3.96 0 6 0 C6 3.96 6 7.92 6 12 C4.02 12 2.04 12 0 12 C0 8.04 0 4.08 0 0 Z "
      fill="white"
      transform="translate(46,15)"
    />
  </svg>
)

export default ScreenIcon
