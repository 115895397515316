const BTIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="36px"
    height="36px"
    viewBox="0 0 98.000000 98.000000"
  >
    <g
      transform="translate(0.000000,98.000000) scale(0.100000,-0.100000)"
      fill="white"
      stroke="none"
    >
      <path
        d="M440 771 c-5 -11 -10 -65 -10 -121 l0 -101 -60 41 c-61 42 -72 43
-90 10 -8 -15 0 -24 55 -60 36 -23 65 -46 65 -50 0 -4 -29 -27 -65 -50 -55
-36 -63 -45 -55 -60 18 -33 29 -32 90 10 l60 41 0 -101 c0 -100 9 -140 33
-140 7 1 37 19 67 41 30 21 81 57 113 79 88 62 80 77 -75 157 -15 8 -30 18
-33 22 -2 5 33 35 80 67 115 80 114 87 -30 170 -60 35 -115 64 -122 64 -7 0
-17 -9 -23 -19z m163 -131 c7 -6 -84 -80 -98 -80 -3 0 -5 32 -5 70 l0 71 48
-27 c26 -15 51 -30 55 -34z m-49 -236 c25 -14 48 -29 51 -34 2 -4 -20 -24 -50
-44 l-55 -36 0 70 c0 38 2 70 4 70 2 0 24 -12 50 -26z"
      />
    </g>
  </svg>
)
export default BTIcon
