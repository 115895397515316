const AccountIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="36px"
    height="36px"
    viewBox="0 0 98.000000 98.000000"
  >
    <g
      transform="translate(0.000000,98.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M421 694 c-31 -22 -52 -75 -45 -113 3 -16 19 -42 36 -58 23 -22 39
-28 73 -28 60 0 95 34 102 99 5 42 3 48 -30 81 -28 28 -43 35 -74 35 -21 0
-49 -7 -62 -16z"
      />
      <path
        d="M350 443 c-45 -18 -69 -47 -88 -103 -17 -48 -18 -61 -7 -70 8 -7 88
-9 236 -8 l224 3 -3 35 c-4 47 -31 99 -65 124 -25 18 -45 21 -155 23 -70 1
-134 -1 -142 -4z"
      />
    </g>
  </svg>
)

export default AccountIcon
