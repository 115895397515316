const SoundIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="36px"
    height="36px"
    viewBox="0 0 98.000000 98.000000"
  >
    <path
      d="M0 0 C2.8125 -0.3125 2.8125 -0.3125 6 0 C7.05771739 1.63064764 8.05273046 3.30280873 9 5 C9.969375 5.474375 10.93875 5.94875 11.9375 6.4375 C16.02570324 8.52331798 18.09815144 11.37751931 19.59375 15.7265625 C20.17116339 18.95785664 20.11924297 22.15791232 20.1875 25.4375 C20.32856903 30.4522611 20.32856903 30.4522611 22.328125 34.953125 C24.11721258 36.37629168 24.11721258 36.37629168 27 38 C29.07051894 41.01166392 29.29380793 43.37636881 29 47 C27.38823694 48.61176306 25.64848556 48.12733842 23.42016602 48.12939453 C21.95843712 48.13412277 21.95843712 48.13412277 20.46717834 48.13894653 C19.40679855 48.1369223 18.34641876 48.13489807 17.25390625 48.1328125 C15.63173439 48.13424759 15.63173439 48.13424759 13.97679138 48.13571167 C11.68431541 48.13639371 9.39183752 48.13453777 7.09936523 48.13037109 C3.58117345 48.12502836 0.06310963 48.13031264 -3.45507812 48.13671875 C-5.67708362 48.13605787 -7.89908903 48.13477651 -10.12109375 48.1328125 C-11.70862709 48.13584885 -11.70862709 48.13584885 -13.32823181 48.13894653 C-14.3058699 48.13579437 -15.283508 48.13264221 -16.29077148 48.12939453 C-17.58530495 48.12820114 -17.58530495 48.12820114 -18.9059906 48.12698364 C-21 48 -21 48 -23 47 C-22.94867775 44.02330927 -22.90175001 41.79800145 -22 39 C-20.41015625 37.66015625 -20.41015625 37.66015625 -18.5 36.4375 C-15.51359808 34.24018802 -15.04807412 33.28779507 -14.421875 29.5390625 C-14.30716613 26.88511639 -14.21867563 24.22990908 -14.15625 21.57421875 C-13.90193192 15.75669265 -12.40596913 12.62227208 -8.5625 8.1875 C-6 6 -6 6 -3.8125 4.9375 C-1.47139069 3.72658139 -0.95928874 2.39822186 0 0 Z "
      fill="white"
      transform="translate(46,21)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C6.1482685 2.296537 6.11561175 3.58313167 6.09765625 6.1328125 C6.09282227 7.34130859 6.09282227 7.34130859 6.08789062 8.57421875 C6.07951172 9.41597656 6.07113281 10.25773437 6.0625 11.125 C6.05798828 11.97449219 6.05347656 12.82398438 6.04882812 13.69921875 C6.03702874 15.79950909 6.01909306 17.8997633 6 20 C4.00045254 20.04254356 1.99958364 20.04080783 0 20 C-1.87634259 18.12365741 -1.13246191 15.459175 -1.13671875 12.96679688 C-1.13285156 12.17337891 -1.12898438 11.37996094 -1.125 10.5625 C-1.12886719 9.77294922 -1.13273437 8.98339844 -1.13671875 8.16992188 C-1.12663067 2.25326133 -1.12663067 2.25326133 0 0 Z "
      fill="white"
      transform="translate(24,35)"
    />
    <path
      d="M0 0 C1.65 0.33 3.3 0.66 5 1 C5 7.27 5 13.54 5 20 C3.02 20 1.04 20 -1 20 C-1.02888514 16.85418577 -1.04675923 13.70840531 -1.0625 10.5625 C-1.07087891 9.66466797 -1.07925781 8.76683594 -1.08789062 7.84179688 C-1.09111328 6.98779297 -1.09433594 6.13378906 -1.09765625 5.25390625 C-1.10551147 4.06776733 -1.10551147 4.06776733 -1.11352539 2.85766602 C-1 1 -1 1 0 0 Z "
      fill="white"
      transform="translate(70,35)"
    />
    <path
      d="M0 0 C1.65 0 3.3 0 5 0 C5 3.63 5 7.26 5 11 C3.35 11 1.7 11 0 11 C-1 10 -1 10 -1.1328125 7.96875 C-1.13023438 7.1540625 -1.12765625 6.339375 -1.125 5.5 C-1.12757812 4.6853125 -1.13015625 3.870625 -1.1328125 3.03125 C-1 1 -1 1 0 0 Z "
      fill="white"
      transform="translate(17,40)"
    />
    <path
      d="M0 0 C1.67542976 0.28604898 3.34385343 0.61781233 5 1 C5 3.97 5 6.94 5 10 C3.34385343 10.38218767 1.67542976 10.71395102 0 11 C-1 10 -1 10 -1.1328125 7.96875 C-1.13023438 7.1540625 -1.12765625 6.339375 -1.125 5.5 C-1.12757812 4.6853125 -1.13015625 3.870625 -1.1328125 3.03125 C-1 1 -1 1 0 0 Z "
      fill="white"
      transform="translate(77,40)"
    />
    <path
      d="M0 0 C3.3 0 6.6 0 10 0 C9.125 4.875 9.125 4.875 8 6 C5.0625 6.1875 5.0625 6.1875 2 6 C1.34 5.34 0.68 4.68 0 4 C0 2.68 0 1.36 0 0 Z "
      fill="white"
      transform="translate(44,71)"
    />
  </svg>
)

export default SoundIcon
